import React from 'react';
import { graphql } from 'gatsby';

import Footer from '../../../components/Footer';
import Header from '../../../components/Header';
import Layout from '../../../components/atoms/layout';
import PageTitle from '../../../components/PageTitle';
import { Question } from '../../../template/legal/styles';
import TermOfUseProps from '../../../interface/template';
import { Text2, Text3 } from '../../../components/atoms/body';
import withI18next from '../../../i18n/TemplateI18n';

const TermOfUse: React.SFC<any> = (props: TermOfUseProps) => {
  const { pageContext } = props;
  const { pageName } = pageContext;

  return (
    <>
      <Header pageName={pageName} showBackground>
        <PageTitle subtitle="" title="Termos de uso" />
      </Header>
      <Layout>
        <Question>
          <Text3 as="p">Bem-vindo ao site da Ironhack</Text3>
          <Text3 as="p">
            Bem-vindo ao site da Ironhack - e obrigado pela sua visita.
            Esperamos que você aproveite a experiência!
          </Text3>
          <Text3 as="p">
            Ironhack, Inc.(“<strong>Ironhack</strong>”, “<strong>nó</strong>”, “
            <strong>nós</strong>” ou “<strong>nosso</strong>”) é um provedor
            internacional de cursos em Desenvolvimento Web e UX/UI Design com
            locais em todo o mundo. Estes Termos de Uso (“
            <strong>Termos</strong>”) são um contrato legal entre você (“
            <strong>você</strong>”, “<strong>seu</strong>”) e nós e regem seu
            uso de todo o texto, dados, informações, software, gráficos,
            fotografias e mais (todos os quais nos referimos como “
            <strong>Materiais</strong>”), que nós e nossas afiliadas podemos
            disponibilizar a você, bem como quaisquer serviços (“
            <strong>Serviços</strong>”) que possamos fornecer através de nosso
            website (todos os quais são referidos nestes Termos como este “
            <strong>Website</strong>”)
          </Text3>
          <Text3 as="p">
            LEIA ESTES TERMOS CUIDADOSAMENTE ANTES DE NAVEGAR POR ESTE WEBSITE.
            O USO DESTE WEBSITE INDICA QUE VOCÊ LEU E ACEITOU ESTES TERMOS. VOCÊ
            NÃO PODERÁ USAR ESTE WEBSITE SE NÃO ACEITAR ESTES TERMOS.
          </Text3>
          <Text3 as="p">
            NOTA: ESTES TERMOS CONTÊM UMA CLÁUSULA DE RESOLUÇÃO DE DISPUTA E
            ARBITRAGEM, INCLUINDO RENÚNCIA DE AÇÃO COLETIVA QUE AFETA SEUS
            DIREITOS SOB ESTES TERMOS E COM RELAÇÃO A DISPUTAS QUE VOCÊ POSSA
            TER COM A EMPRESA. VOCÊ PODE OPTAR POR NÃO PARTICIPAR DA ARBITRAGEM
            INDIVIDUAL OBRIGATÓRIA E DA RENÚNCIA A AÇÃO COLETIVA, CONFORME
            PREVISTO ABAIXO.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">MUDANÇAS</Text2>
          <Text3 as="p">
            Podemos alterar os Materiais e Serviços que lhe oferecemos e/ou
            optar por modificar, suspender ou descontinuar este Site a qualquer
            momento e sem notificá-lo. Também podemos alterar, atualizar,
            adicionar ou remover provisões (coletivamente, "modificações")
            destes Termos de Uso de tempos em tempos. Como todos se beneficiam
            da clareza, prometemos informá-lo de quaisquer modificações a estes
            Termos, publicando-os neste Website.
          </Text3>
          <Text3 as="p">
            Se você se opuser a tais modificações, seu único recurso será cessar
            o uso deste Website. O uso continuado deste Website após o aviso de
            tais modificações indica que você reconhece e concorda em ficar
            vinculado às modificações. Além disso, saiba que estes Termos podem
            ser substituídos por avisos legais expressamente designados ou
            termos localizados em páginas específicas deste Website. Estes
            avisos ou termos legais expressamente designados são incorporados a
            estes Termos e para substituir a(s) disposição(ões) destes Termos
            que são designados como tendo sido substituídos.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">USO GERAL</Text2>
          <Text3 as="p">
            Ao utilizar este site, você promete que tem pelo menos 18 anos de
            idade. Se você ainda não tiver 18 anos de idade, deverá ter a
            permissão de um adulto para usar este Website e concordar com seus
            Termos, e esse adulto deverá ser um pai ou tutor legal que esteja
            disposto a ser responsável pelo uso deste Website.
          </Text3>
          <Text3 as="p">
            Convidamos você a usar este Website para fins individuais, de
            consumo (“
            <strong>Propósitos Permitidos</strong>”) - aproveite!
          </Text3>
          <Text3 as="p">
            Nestes Termos estamos concedendo a você uma licença limitada,
            pessoal, não exclusiva e intransferível para usar e exibir os
            Materiais; Seu direito de usar os Materiais está condicionado ao seu
            cumprimento destes Termos. Você não tem outros direitos neste
            Website ou em quaisquer Materiais e não poderá modificar, editar,
            copiar, reproduzir, criar trabalhos derivados, fazer engenharia
            reversa, alterar, melhorar ou de qualquer forma explorar de qualquer
            forma qualquer um deste Website ou Materiais. Se você fizer cópias
            de qualquer um destes Websites enquanto estiver envolvido em Fins
            Permitidos, pedimos que você tenha certeza de manter nas cópias
            todos os nossos avisos de direitos autorais e outros avisos de
            propriedade conforme eles aparecem neste Website.
          </Text3>
          <Text3 as="p">
            Infelizmente, se você violar qualquer um destes Termos, a licença
            acima terminará automaticamente e você deverá destruir imediatamente
            quaisquer Materiais baixados ou impressos (e quaisquer cópias dos
            mesmos).
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">USO DESTE WEBSITE E DE NOSSOS SERVIÇOS</Text2>
          <Text3 as="p">
            Agradecemos sua visita a este site e permitimos que você faça
            exatamente isso - passe por aqui e confira-o sem se candidatar a
            nós!
          </Text3>
          <Text3 as="p">
            Entretanto, para acessar determinados Serviços, nossos instrutores e
            materiais especializados do curso, você deve primeiro se inscrever,
            depois ser aceito em um de nossos programas de Desenvolvimento Web
            ou UX/UI Design Bootcamps ou em tempo parcial (“
            <strong>Ironhack Bootcamp</strong>”).
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">REGISTRO NA IRONHACK</Text2>
          <Text3 as="p">
            Se pretender registrar-se num Bootcamp da Ironhack, deve apresentar
            as seguintes informações, após clicar no botão “
            <a href="https://www.ironhack.com/br/cursos/inscrever">Apply now</a>
            ” (Registrar) deste website:
          </Text3>
          <Text3 as="p">
            <ul>
              <li>Nome e apelido;</li>
              <li>Número de telefone;</li>
              <li>Um endereço de correio eletrónico ativo;</li>
              <li>Género;</li>
              <li>Referência; e</li>
              <li>
                Uma breve descrição das razões do seu interesse na Ironhack.
              </li>
            </ul>
            Durante o processo de registro poderá, de forma opcional, fornecer
            informações adicionais, que nos permita proporcionar-lhe uma
            experiência mais personalizada ao utilizar este website, no entanto,
            deixamos esta opção ao seu critério.
          </Text3>
          <Text3 as="p">
            Depois de ter submetido as informações solicitadas, determinaremos
            se a sua inscrição será aceita. Se a mesma for aprovada, receberá
            posteriormente um e-mail, com os detalhes sobre os passos
            necessários para completar o processo de registro. Se não submeter
            as informações acima enumeradas, não será possível processar o seu
            formulário de registro ou fornecer-lhe os nossos serviços da forma
            mais adequada.
          </Text3>
          <Text3 as="p">
            Ao acessar este website, você é responsável pelo cumprimento destes
            Termos e Condições. É da sua responsabilidade obter e manter o
            equipamento e os serviços técnicos necessários para utilizar este
            website, assim como, também, pagar quaisquer encargos associados que
            se revelem necessários. Você deverá ainda, manter a
            confidencialidade das informações que nos fornecer durante o seu
            registro para o Bootcamp Ironhack. Se considerar que a sua
            informação pessoal ou a sua segurança foi violada de alguma forma,
            no âmbito da utilização deste website, deverá notificar-nos
            imediatamente. Se necessitar de mais informações, por favor visite a
            nossa{' '}
            <a href="https://www.ironhack.com/br/legal/aviso-legal">
              Política de Privacidade
            </a>
            .
          </Text3>
          <Text2 as="h2">APLICAR-SE PARA IRONHACK</Text2>
          <Text3 as="p">
            Se você quiser se candidatar a um Bootcamp da Ironhack, você deve
            enviar as seguintes informações após clicar no botão "Aplicar agora"
            neste site:
          </Text3>
          <Text3 as="p">
            <ul>
              <li>Nome e sobrenome;</li>
              <li>Número de telefone;</li>
              <li>Um endereço de e-mail funcional;</li>
              <li>Género;</li>
              <li>Fonte de referência; e</li>
              <li>
                Descreva brevemente porque você está interessado na Ironhack.
              </li>
            </ul>
            Durante o processo de inscrição, você também pode fornecer
            informações adicionais e opcionais para que possamos lhe
            proporcionar uma experiência mais personalizada ao utilizar este
            Website - mas, deixaremos essa decisão com você. Uma vez enviadas as
            informações necessárias, somente nós determinaremos se aprovamos ou
            não sua solicitação. Se aprovado, você receberá um e-mail detalhando
            as etapas necessárias para completar o processo de inscrição.
          </Text3>
          <Text3 as="p">
            Você é responsável pelo cumprimento destes Termos ao acessar este
            Website. É seu trabalho obter e manter todos os equipamentos e
            serviços técnicos necessários para o acesso e uso deste Website, bem
            como para o pagamento de todos os encargos relacionados. É também
            sua responsabilidade manter a confidencialidade de qualquer
            informação que nos forneça durante a solicitação de um Bootcamp da
            Ironhack. Caso você acredite que suas informações pessoais ou
            segurança para este Website tenham sido violadas de alguma forma,
            você deve nos notificar imediatamente. .
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">COMUNICAÇÕES ELETRÔNICAS</Text2>
          <Text3 as="p">
            Ao utilizar o Website, você consente em receber nossas comunicações
            eletrônicas, inclusive via e-mail. Essas comunicações eletrônicas
            podem incluir avisos sobre taxas e encargos aplicáveis, informações
            sobre transações e outras informações relativas ou relacionadas ao
            Website ou a nossos cursos. Estas comunicações eletrônicas são parte
            de seu relacionamento conosco. Você concorda que quaisquer avisos,
            acordos, divulgações ou outras comunicações que lhe enviarmos
            eletronicamente irão satisfazer quaisquer exigências legais de
            comunicação, incluindo que tais comunicações sejam por escrito.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">POLÍTICA DE PRIVACIDADE</Text2>
          <Text3 as="p">
            Respeitamos as informações que você nos fornece, e queremos ter
            certeza de que você entende perfeitamente como usamos essas
            informações. Portanto, por favor, reveja nossa “
            <a href="https://www.ironhack.com/br/legal/aviso-legal">
              <strong>Política de Privacidade</strong>
            </a>
            ”, que explica tudo.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">LINKS PARA SITES DE TERCEIROS</Text2>
          <Text3 as="p">
            Você pode querer nos seguir, conectar-se conosco em mídias sociais,
            ou acessar outras páginas da web relacionadas referenciadas em nossa
            página de blog ou Website. Achamos que os links são convenientes, e
            às vezes fornecemos links neste Website para websites de terceiros.
            Se você usar estes links, sairá deste Website. Não somos obrigados a
            rever quaisquer websites de terceiros para os quais você estabeleça
            links a partir deste Website, não controlamos nenhum dos websites de
            terceiros e não somos responsáveis por nenhum dos websites de
            terceiros (ou pelos produtos, serviços ou conteúdo disponível
            através de qualquer um deles). Assim, não endossamos ou fazemos
            qualquer representação sobre tais websites de terceiros, qualquer
            informação, software, produtos, serviços ou materiais lá encontrados
            ou quaisquer resultados que possam ser obtidos a partir do uso dos
            mesmos. Se você decidir acessar qualquer um dos websites de
            terceiros vinculados a partir deste Website, você o fará
            inteiramente por sua própria conta e risco e deverá seguir as
            políticas de privacidade e os termos e condições para esses websites
            de terceiros separados.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">SUBMISSÕES</Text2>
          <Text3 as="p">
            Algumas áreas deste website (por exemplo, o recurso de comentários
            no blog) podem permitir que você envie comentários, informações,
            dados, texto, mensagens ou outros materiais (cada um, uma "
            <strong>Submissão do usuário</strong>”). Você concorda que é o único
            responsável por todos os seus Envios de Usuário e que qualquer Envio
            de Usuário é considerado não-confidencial e não-proprietário. Além
            disso, não garantimos que você será capaz de editar ou excluir
            qualquer Transferência do Usuário que tenha enviado.
          </Text3>
          <Text3 as="p">
            Ao enviar qualquer Envio do Usuário, você nos promete isso:
          </Text3>
          <Text3 as="p">
            <ul>
              <li>
                Você possui todos os direitos em suas Transferências do Usuário
                (incluindo, sem limitação, todos os direitos à reprodução e
                exibição de suas Transferências do Usuário) ou,
                alternativamente, você adquiriu todos os direitos necessários em
                suas Transferências do Usuário para permitir que você nos
                conceda os direitos em suas Transferências do Usuário, conforme
                descrito nestes Termos;
              </li>
              <li>
                Você pagou e pagará integralmente todas as taxas de licença,
                taxas de liberação e outras obrigações financeiras, de qualquer
                tipo, decorrentes de qualquer uso ou exploração comercial de
                seus Envios do Usuário;
              </li>
              <li>
                Seus Envios do Usuário não infringem os direitos autorais,
                marcas registradas, patentes, segredos comerciais ou outros
                direitos de propriedade intelectual, direitos de privacidade ou
                quaisquer outros direitos legais ou morais de terceiros;
              </li>
              <li>
                Você concorda voluntariamente em renunciar a todos os 'direitos
                morais' que você possa ter em suas Transferências do Usuário;
              </li>
              <li>
                Qualquer informação contida em sua Transferência de Usuário não
                é conhecida por você como falsa, imprecisa ou enganosa;
              </li>
              <li>
                Sua Transferência de Usuário não viola nenhuma lei (incluindo,
                mas não se limitando àquelas que regem o controle de exportação,
                proteção ao consumidor, concorrência desleal, anti-discriminação
                ou publicidade enganosa);
              </li>
              <li>
                Sua Transferência de Usuário não é, e não pode ser razoavelmente
                considerada difamatória, caluniosa, odiosa, racial, étnica,
                religiosa ou de outra forma tendenciosa ou ofensiva, ilegalmente
                ameaçadora, ou ilegalmente assediadora a qualquer indivíduo,
                sociedade ou corporação, vulgar, pornográfica, obscena ou
                invasiva da privacidade de outra pessoa;
              </li>
              <li>
                Você não foi e não será compensado ou não receberá nenhuma
                consideração de terceiros por ter submetido sua submissão de
                usuário;
              </li>
              <li>
                Seu Envio de Usuário não incorpora materiais de um website de
                terceiros, ou endereços, endereços de e-mail, informações de
                contato ou números de telefone (que não sejam seus próprios);
              </li>
              <li>
                Sua Transferência de Usuário não contém nenhum vírus, worms,
                spyware, adware ou outros programas ou arquivos potencialmente
                prejudiciais;
              </li>
              <li>
                Sua Transferência de usuário não contém nenhuma informação que
                você considere confidencial, proprietária ou pessoal; e
              </li>
              <li>
                Seu envio ao usuário não contém ou constitui qualquer
                publicidade não solicitada ou não autorizada, materiais
                promocionais, lixo eletrônico, spam, cartas em cadeia, esquemas
                em pirâmide ou qualquer outra forma de solicitação.
              </li>
            </ul>
            Ao enviar um Envio de Usuário, Você nos concede uma licença
            irrevogável, perpétua, transferível, não exclusiva, totalmente paga,
            mundial, livre de royalties (sublicenciável através de múltiplos
            níveis) para:
          </Text3>
          <Text3 as="p">
            <ul>
              <li>
                Usar, distribuir, reproduzir, modificar, adaptar, publicar,
                traduzir, executar publicamente e exibir publicamente seus
                Envios de Usuário (ou qualquer modificação dos mesmos), no todo
                ou em parte, em qualquer formato ou meio agora conhecido ou
                desenvolvido posteriormente;
              </li>
              <li>
                Usar (e permitir que outros usem) sua Transferência de Usuário
                de qualquer maneira e para qualquer finalidade (incluindo, sem
                limitação, fins comerciais) que julgarmos apropriados a nosso
                exclusivo critério (incluindo, sem limitação, incorporar sua
                Transferência de Usuário ou qualquer modificação da mesma, no
                todo ou em parte, em qualquer tecnologia, produto ou serviço);
              </li>
              <li>
                Exibir anúncios em conexão com seus Envios de Usuário e usar
                seus Envios de Usuário para fins publicitários e promocionais.
              </li>
            </ul>
            Não toleramos quaisquer Envios do Usuário prejudiciais ou ofensivos
            em nosso Website. Podemos, mas não somos obrigados a, pré-visualizar
            os Envios do Usuário ou monitorar qualquer área deste Website
            através da qual os Envios do Usuário podem ser enviados. Não somos
            obrigados a hospedar, exibir ou distribuir quaisquer Envios do
            Usuário neste Website ou através dele e podemos remover a qualquer
            momento ou recusar quaisquer Envios do Usuário por qualquer motivo.
            Não somos responsáveis por qualquer perda, roubo ou dano de qualquer
            tipo a quaisquer Envios do Usuário. Além disso, você concorda que
            poderemos divulgar livremente seus Envios do Usuário a qualquer
            terceiro, sem qualquer obrigação de confiança por parte do
            destinatário.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">ATIVIDADES NÃO AUTORIZADAS</Text2>
          <Text3 as="p">
            Para ser claro, autorizamos seu uso deste site somente para Fins
            Permitidos. Qualquer outro uso deste Website além dos Fins
            Permitidos é proibido e, portanto, constitui uso não autorizado
            deste Website. Isto porque, entre você e nós, todos os direitos
            neste Website continuam sendo de nossa propriedade.
          </Text3>
          <Text3 as="p">
            O uso não autorizado deste Website pode resultar em violação de
            várias leis de direitos autorais dos Estados Unidos e
            internacionais. Como preferimos manter esta relação sem dramas,
            queremos dar a você exemplos de coisas a evitar. Portanto, a menos
            que você tenha permissão por escrito da nossa parte, você não está
            autorizado a usar este Website de nenhuma das seguintes maneiras
            (estes são apenas exemplos e a lista abaixo não é uma lista completa
            de tudo o que você não tem permissão para fazer):
          </Text3>
          <Text3 as="p">
            <ul>
              <li>
                Para qualquer finalidade pública ou comercial que inclua o uso
                deste Website em outro site ou através de um ambiente de
                computador em rede;
              </li>
              <li>
                De uma maneira que modifique, exiba publicamente, execute
                publicamente, reproduza ou distribua qualquer um deste Website;
              </li>
              <li>
                De uma maneira que viole qualquer estatuto, regulamento, regra,
                ordem, tratado ou outra lei local, estadual, nacional,
                estrangeira ou internacional;
              </li>
              <li>Para perseguir, assediar ou prejudicar outro indivíduo;</li>
              <li>
                Fazer-se passar por qualquer pessoa ou entidade ou de outra
                forma deturpar sua afiliação com uma pessoa ou entidade;
              </li>
              <li>
                Interferir ou perturbar este Website ou servidores ou redes
                conectadas a este Website; Para usar qualquer método de
                mineração de dados, robôs ou métodos similares de coleta ou
                extração de dados em conexão com este site; ou
              </li>
              <li>
                Tentar obter acesso não autorizado a qualquer parte deste
                Website ou a quaisquer outras contas, sistemas de computador ou
                redes conectadas a este Website, seja através de hacking,
                mineração de senhas ou qualquer outro meio.
              </li>
            </ul>
            Você concorda em contratar advogados para nos defender se você
            violar estes Termos e essa violação resultar em um problema para
            nós. Você também concorda em pagar quaisquer danos que possamos ter
            que pagar como resultado de sua violação. Você é o único responsável
            por qualquer violação destes Termos por você. Reservamo-nos o
            direito de assumir a exclusiva defesa e controle de qualquer assunto
            sujeito a indenização por você e, em tal caso, você concorda em
            cooperar com nossa defesa de tal reivindicação.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">DIREITOS DE PROPRIEDADE</Text2>
          <Text3 as="p">
            "Ironhack" é uma marca que nos pertence. Outras marcas, nomes e
            logotipos neste site são de propriedade de seus respectivos
            proprietários.
          </Text3>
          <Text3 as="p">
            Salvo disposição em contrário nestes Termos, todos os Materiais,
            incluindo a disposição dos mesmos neste Website são de nossa
            exclusiva propriedade, Copyright © 2018. Todos os direitos não
            expressamente concedidos neste Site são reservados. Exceto quando
            exigido ou limitado pela lei aplicável, qualquer reprodução,
            distribuição, modificação, retransmissão ou publicação de qualquer
            material protegido por direitos autorais é estritamente proibida sem
            o consentimento expresso por escrito do proprietário dos direitos
            autorais ou licença.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">VIOLAÇÃO DE PROPRIEDADE INTELECTUAL</Text2>
          <Text3 as="p">
            Respeitamos os direitos de propriedade intelectual dos outros e o
            incentivamos a fazer o mesmo. Assim, temos a política de remover os
            Envios de Usuário que violem os direitos de propriedade intelectual
            de terceiros, suspendendo o acesso a este Website (ou qualquer parte
            dele) a qualquer usuário que utilize este Website em violação aos
            direitos de propriedade intelectual de alguém, e/ou terminando em
            circunstâncias apropriadas o Bootcamp Ironhack de qualquer usuário
            que utilize este Website em violação aos direitos de propriedade
            intelectual de alguém.
          </Text3>
          <Text3 as="p">
            De acordo com o Título 17 do Código dos Estados Unidos, Seção 512,
            implementamos procedimentos para receber notificação por escrito da
            violação de direitos autorais reivindicada e para processar tais
            reivindicações de acordo com tal lei. Se você acredita que seus
            direitos autorais ou outros direitos de propriedade intelectual
            estão sendo violados por um usuário deste Website, favor fornecer
            notificação por escrito à Ironhack para notificação de reclamações
            de violação:
          </Text3>
          <Text3 as="p">Attn: Agente da Ironhack DMCA</Text3>
          <Text3 as="p">
            Email:
            <a href="mailto: legal@ironhack.com">legal@ironhack.com</a>
          </Text3>
          <Text3 as="p">
            Para ter certeza de que o assunto será tratado imediatamente, sua
            notificação por escrito deve ser enviada:
          </Text3>
          <Text3 as="p">
            <ul>
              <li>Conter sua assinatura física ou eletrônica;</li>
              <li>
                Identificar o trabalho protegido por direitos autorais ou outra
                propriedade intelectual alegadamente infringida;
              </li>
              <li>
                Identificar o material supostamente violado de maneira
                suficientemente precisa para nos permitir localizar esse
                material;
              </li>
              <li>
                Conter informações adequadas pelas quais possamos entrar em
                contato com você (incluindo endereço postal, número de telefone
                e endereço de e-mail);
              </li>
              <li>
                Conter uma declaração de que você acredita de boa fé que o uso
                do material protegido por direitos autorais ou outra propriedade
                intelectual não é autorizado pelo proprietário, pelo agente do
                proprietário ou pela lei;
              </li>
              <li>
                Conter uma declaração de que as informações contidas no aviso
                por escrito são precisas; e Conter declaração, sob pena de
                perjúrio, de que você está autorizado a agir em nome do
                proprietário dos direitos autorais ou outros direitos de
                propriedade intelectual.
              </li>
            </ul>
            A menos que o aviso diga respeito a violação de direitos autorais ou
            outra violação de propriedade intelectual, o Agente DMCA da Ironhack
            não será capaz de atender à preocupação listada.
          </Text3>
          <Text3 as="p">Envio de uma contra-notificação DMCA</Text3>
          <Text3 as="p">
            Nós o notificaremos que removemos ou desativamos o acesso ao
            material protegido por direitos autorais que você forneceu, se tal
            remoção estiver de acordo com um aviso de retirada de DMCA
            validamente recebido. Em resposta, você pode fornecer ao agente DMCA
            da Ironhack uma contra-notificação por escrito que inclui as
            seguintes informações:
          </Text3>
          <Text3 as="p">
            <ol>
              <li>Sua assinatura física ou eletrônica;</li>
              <li>
                Identificação do material que foi removido ou cujo acesso foi
                desativado, e o local em que o material apareceu antes de ser
                removido ou o acesso a ele foi desativado;
              </li>
              <li>
                Uma declaração sua, sob pena de perjúrio, de que você acredita
                de boa fé que o material foi removido ou desabilitado como
                resultado de um erro ou identificação errada do material a ser
                removido ou desabilitado; e
              </li>
              <li>
                Seu nome, endereço físico e número de telefone, e uma declaração
                de que você consente com a jurisdição de um tribunal para o
                distrito judicial no qual seu endereço físico está localizado,
                ou se seu endereço físico estiver fora dos Estados Unidos, para
                qualquer distrito judicial no qual possamos estar localizados, e
                que você aceitará o serviço de processo da pessoa que forneceu a
                notificação de material supostamente infrator ou de um agente de
                tal pessoa.
              </li>
            </ol>
            Rescisão de infratores reincidentes
          </Text3>
          <Text3 as="p">
            Reservamo-nos o direito, a nosso exclusivo critério, de encerrar um
            Bootcamp da Ironhack ou o acesso de qualquer usuário deste Website
            ou Serviço que esteja sujeito a repetidas notificações de DMCA ou
            outras notificações de infração. Os avisos e contra-avisos devem
            atender aos requisitos legais então vigentes impostos pela DMCA;
            veja https://www.copyright.gov/ para detalhes. Consulte seu
            consultor jurídico e veja 17 U.S.C. § 512 antes de apresentar uma
            notificação ou contra-aviso, pois há penalidades para falsas
            reivindicações sob o DMCA.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">ISENÇÃO DE GARANTIAS</Text2>
          <Text3 as="p">
            ESTE WEBSITE É FORNECIDO "COMO ESTÁ" E "COM TODAS AS FALHAS" E TODO
            O RISCO QUANTO À QUALIDADE E DESEMPENHO DESTE WEBSITE ESTÁ COM VOCÊ.
          </Text3>
          <Text3 as="p">
            NÓS EXPRESSAMENTE NEGAMOS TODAS AS GARANTIAS DE QUALQUER TIPO
            (EXPRESSAS, IMPLÍCITAS OU ESTATUTÁRIAS) COM RESPEITO A ESTE WEBSITE,
            QUE INCLUI, MAS NÃO SE LIMITA A, QUAISQUER GARANTIAS IMPLÍCITAS OU
            ESTATUTÁRIAS DE COMERCIABILIDADE, ADEQUAÇÃO A UM USO OU PROPÓSITO
            ESPECÍFICO, TÍTULO E NÃO VIOLAÇÃO DOS DIREITOS DE PROPRIEDADE
            INTELECTUAL.
          </Text3>
          <Text3 as="p">
            ISTO SIGNIFICA QUE NÃO PROMETEMOS A VOCÊ QUE O WEBSITE ESTÁ LIVRE DE
            PROBLEMAS. Sem limitar a generalidade do precedente, não garantimos
            que este website atenderá às suas exigências ou que este website
            será ininterrupto, oportuno, seguro, livre de erros, ou que os
            defeitos neste website serão corrigidos.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">RESULTADO</Text2>
          <Text3 as="p">
            Não garantimos os resultados que podem ser obtidos com o uso deste
            site ou a precisão ou confiabilidade de qualquer informação obtida
            através deste site. Nenhum conselho ou informação, oral ou escrita,
            obtida por Você através deste Website ou de nós ou de nossas
            subsidiárias/outras empresas afiliadas, criará qualquer garantia.
            Renunciamos a todas as indenizações equitativas.
          </Text3>
          <Text3 as="p">
            Nós nos esforçamos para recrutar instrutores e funcionários de
            primeira linha para lhe proporcionar a melhor experiência da
            Ironhack Bootcamp. Podemos apresentar um determinado instrutor da
            Ironhack Bootcamp como um anúncio, mas isto não é uma recomendação
            de tal instrutor específico ou uma garantia de que seu curso será
            conduzido por tal instrutor. Não fazemos nenhuma representação
            quanto à qualidade ou natureza de qualquer instrutor específico da
            Ironhack Bootcamp.
          </Text3>
          <Text3 as="p">
            De tempos em tempos, podemos divulgar e promover os resultados de
            certos ex-alunos de destaque da Ironhack Bootcamp neste website e em
            nossas mídias sociais ou pontos de marketing. Em nenhum caso a
            Ironhack garante qualquer resultado, qualquer emprego, qualquer
            salário ou qualquer fim profissional em particular. O Bootcamp da
            Ironhack não é um programa de colocação profissional, e certos
            resultados podem não ser replicados. Nós nos esforçamos para ser
            completamente transparentes com nossa apresentação de testemunhos de
            ex-alunos da Ironhack, resultados e resultados anteriores, incluindo
            classificações médias e exceções. É nosso objetivo dar aos
            participantes do Bootcamp da Ironhack os dados para entender que os
            resultados passados não são preditores de resultados futuros.
          </Text3>
          <Text3 as="p">
            SE UM EX-ALUNO DA IRONHACK CONSEGUIU UM ÓTIMO TRABALHO APÓS
            COMPLETAR UM BOOTCAMP DA IRONHACK, ESSE FATO NÃO É UMA GARANTIA DE
            QUE VOCÊ TERÁ O MESMO RESULTADO. OS RESULTADOS SÃO ALTAMENTE
            VARIÁVEIS POR LOCALIZAÇÃO E UMA FUNÇÃO DAS CONDIÇÕES DE MERCADO FORA
            DE NOSSO CONTROLE. NÓS NÃO GARANTIMOS NENHUM RESULTADO.
          </Text3>
          <Text3 as="p">
            Completar um Bootcamp da Ironhack não garante que você se tornará
            proficiente no assunto ensinado no Bootcamp da Ironhack. Nossos
            instrutores e funcionários trabalham para orientar o aprendizado em
            todo o Bootcamp da Ironhack, mas alguns alunos não têm sucesso.
            <strong>
              Alguns alunos que se formam no Bootcamp da Ironhack não se tornam
              empregados no campo.
            </strong>
            Alguns estudantes não se formam, e outros não se tornam habilidosos
            ou capazes na matéria ensinada no Ironhack Bootcamp. Você deve falar
            com um gerente de programa da Ironhack sobre os diferentes locais e
            horários, requisitos de admissão, todas as taxas e custos associados
            e sua capacidade antes de se matricular em qualquer Ironhack
            Bootcamp.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">LIMITAÇÃO DE RESPONSABILIDADE</Text2>
          <Text3 as="p">
            NÃO SEREMOS RESPONSÁVEIS POR QUAISQUER DANOS RESULTANTES DE SUA
            EXIBIÇÃO, CÓPIA OU DOWNLOAD DE QUAISQUER MATERIAIS DE OU PARA ESTE
            WEBSITE. NA MÁXIMA EXTENSÃO PERMITIDA PELA LEGISLAÇÃO APLICÁVEL, EM
            NENHUMA HIPÓTESE SEREMOS RESPONSÁVEIS PERANTE VOCÊ POR QUAISQUER
            DANOS INDIRETOS, EXTRAORDINÁRIOS, EXEMPLARES, PUNITIVOS, ESPECIAIS,
            INCIDENTAIS OU CONSEQÜENTES (INCLUINDO PERDA DE DADOS, RECEITA,
            LUCROS, USO OU OUTRA VANTAGEM ECONÔMICA), MESMO QUE SAIBAMOS QUE
            EXISTE A POSSIBILIDADE DE TAIS DANOS.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">LEIS LOCAIS DE CONTROLE DE EXPORTAÇÃO</Text2>
          <Text3 as="p">
            Controlamos e operamos este website a partir de nossa sede nos
            Estados Unidos da América e a totalidade deste website pode não ser
            apropriada ou disponível para uso em outros locais. Se você usar
            este website fora dos Estados Unidos da América, você é o único
            responsável por seguir as leis locais aplicáveis.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">FEEDBACK</Text2>
          <Text3 as="p">
            Qualquer envio feito por você para nós (por exemplo, comentários,
            testemunhos, citações, perguntas, sugestões, materiais -
            coletivamente, "Feedback") através de qualquer comunicação (por
            exemplo, chamada, comentários no blog, e-mail) será tratado como
            não-confidencial e não-proprietário. Exceto quando proibido pela lei
            aplicável, você, por meio deste documento, cede todo o direito,
            título e interesse em, e nós somos livres para utilizar, sem
            qualquer atribuição ou compensação a Você, quaisquer idéias,
            know-how, conceitos, técnicas ou outros direitos de propriedade
            intelectual e direitos de propriedade contidos no Feedback,
            patenteáveis ou não, para qualquer finalidade, incluindo, mas não se
            limitando a, desenvolvimento, fabricação, fabricação, licenciamento,
            comercialização e venda, direta ou indiretamente, de produtos e
            serviços que utilizem tal Feedback. Quando a cessão anterior for
            proibida por lei, você nos concede uma licença exclusiva,
            transferível, mundial, livre de royalties, totalmente paga
            (incluindo o direito de sublicenciar) para usar e explorar todo o
            Feedback, conforme determinarmos a nosso exclusivo critério. Não
            obstante o acima exposto, você compreende e concorda que não somos
            obrigados a usar, exibir, reproduzir ou distribuir tais idéias,
            know-how, conceitos ou técnicas contidas no Feedback, e você não tem
            o direito de obrigar tal uso, exibição, reprodução ou distribuição.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">
            RESOLUÇÃO DE DISPUTAS E RENÚNCIA A AÇÕES COLETIVAS ARBITRAIS
          </Text2>
          <Text3 as="p">
            Por favor, leia com cuidado esta provisão. Afeta seus direitos
            legais.
          </Text3>
          <Text3 as="p">
            Esta Disputa de Solução e Arbitragem; Cláusula de Isenção de Ação
            Coletiva (esta “<strong>Cláusula</strong>”) facilita a resolução
            rápida e eficiente de qualquer disputa (por exemplo, reclamação ou
            controvérsia, seja baseada em contrato, estatuto, regulamento,
            portaria, delito civil - incluindo, mas não se limitando a, fraude,
            deturpação, indução fraudulenta ou negligência - ou qualquer outra
            teoria legal ou eqüitativa, e inclui a validade, aplicabilidade ou
            escopo desta Cláusula (com exceção da aplicabilidade da cláusula de
            Isenção de Ação Coletiva abaixo) que possa surgir entre você e nós.
            Efetivamente, então, "disputa" tem o significado mais amplo que a
            lei pode impor e inclui quaisquer reivindicações contra outras
            partes relacionadas aos serviços ou produtos fornecidos ou faturados
            a você (tais como nossos licenciadores, fornecedores, revendedores
            ou vendedores terceirizados) sempre que você também fizer valer
            reivindicações contra nós no mesmo processo.
          </Text3>
          <Text3 as="p">
            Esta disposição prevê que todas as disputas entre você e nós serão
            resolvidas por arbitragem obrigatória, pois a aceitação destes
            Termos constitui uma renúncia de seu direito a reivindicações
            judiciais e toda oportunidade de ser ouvido por um juiz ou júri.
            Preferimos isto porque acreditamos que a arbitragem é mais eficiente
            e menos litigiosa do que o litígio. Para ser claro, não há juiz ou
            júri na arbitragem, e a revisão judicial de uma sentença arbitral é
            limitada. O árbitro deve seguir este acordo e pode conceder os
            mesmos danos e alívio que um tribunal (incluindo honorários
            advocatícios). Você pode, entretanto, optar por não participar desta
            disposição, o que significa que você teria o direito ou a
            oportunidade de apresentar reclamações em um tribunal, perante um
            juiz ou júri, e/ou de participar ou ser representado em um caso
            apresentado em tribunal por outros (incluindo, mas não limitado a,
            ações coletivas). VOCÊ E NÓS DOIS CONCORDAMOS QUE, EXCETO CONFORME
            PREVISTO ABAIXO, TODA E QUALQUER DISPUTA, CONFORME DEFINIDO ACIMA,
            SEJA ATUALMENTE EXISTENTE OU BASEADA EM ATOS OU OMISSÕES NO PASSADO
            OU NO FUTURO, SERÁ RESOLVIDA EXCLUSIVAMENTE E FINALMENTE POR
            ARBITRAGEM OBRIGATÓRIA E NÃO EM TRIBUNAL, DE ACORDO COM ESTA
            DISPOSIÇÃO.
          </Text3>
          <Text3 as="p">Resolução de Reclamações Pré-Arbitro</Text3>
          <Text3 as="p">
            Para todas as disputas, sejam elas judiciais ou arbitrais, você deve
            primeiro nos dar a oportunidade de resolver a disputa, o que
            primeiro é feito enviando-nos um e-mail para{' '}
            <a href="mailto:legal@ironhack.com">legal@ironhack.com</a>, e
            incluindo as seguintes informações: (1) seu nome, (2) seu endereço,
            (3) uma descrição escrita de sua reclamação e a base de sua disputa,
            e (4) uma descrição da medida específica que você procura. Se não
            resolvermos sua disputa dentro de quarenta e cinco (45) dias após
            recebermos sua notificação, então você poderá prosseguir com sua
            disputa em arbitragem. Você pode prosseguir com sua disputa em um
            tribunal somente nas circunstâncias descritas abaixo.
          </Text3>
          <Text3 as="p">Exclusões de Arbitragem/Direito a Optar por Sair</Text3>
          <Text3 as="p">
            Não obstante o acima exposto, você ou nós podemos optar por
            prosseguir com uma disputa em tribunal e não por arbitragem se: (a)
            a disputa se qualificar para ser iniciada em tribunal de pequenas
            causas; ou (b) VOCÊ OPT-OUT DESSES PROCEDIMENTOS DE ARBITRAGEM EM 30
            DIAS A PARTIR DA DATA EM QUE VOCÊ PRIMEIRO CONSENTE A ESTE ACORDO (o
            “<strong>Prazo de Opt-Out</strong>”). PVocê pode optar por não
            participar desta disposição de arbitragem enviando-nos um e-mail
            para <a href="mailto: legal@ironhack.com">legal@ironhack.com</a> as
            seguintes informações: (1) seu nome; (2) seu endereço; (3) uma
            declaração clara de que você não deseja resolver disputas conosco
            através de arbitragem. De qualquer forma, não tomaremos nenhuma
            decisão que você tome pessoalmente. Na verdade, prometemos que sua
            decisão de não participar desta disposição de arbitragem não terá
            nenhum efeito adverso sobre seu relacionamento conosco. Mas, temos
            que impor o Prazo de Opt-Out, portanto, tenha em mente que qualquer
            pedido de opt-out recebido após o Prazo de Opt-Out não será válido e
            você deverá prosseguir com sua disputa no tribunal de arbitragem ou
            de pequenas reclamações.
          </Text3>
          <Text3 as="p">Procedimentos de Arbitragem</Text3>
          <Text3 as="p">
            Se esta disposição se aplicar e a disputa não for resolvida como
            previsto acima (Resolução de Reclamação Pré-Arbitro), ou você ou nós
            podemos iniciar um processo de arbitragem. A Associação Americana de
            Arbitragem “<strong>AAA</strong>”), www.adr.org, ou JAMS,
            www.jamsadr.com, arbitrará todas as disputas, e a arbitragem será
            conduzida perante um único árbitro. A arbitragem será iniciada
            somente como uma arbitragem individual, e em nenhum caso será
            iniciada como uma arbitragem de classe ou uma ação ou arbitragem
            consolidada ou representativa. Todas as questões deverão ser
            decididas pelo árbitro, incluindo o escopo desta disposição da
            arbitragem.
          </Text3>
          <Text3 as="p">
            Para arbitragem perante a AAA, para disputas de valor inferior a
            $75.000, aplicar-se-ão os Procedimentos Suplementares para Disputas
            Relacionadas ao Consumidor da AAA; para disputas envolvendo $75.000
            ou mais, aplicar-se-ão as Regras de Arbitragem Comercial da AAA. Em
            ambos os casos, aplicar-se-ão as Regras Opcionais da AAA para
            Medidas de Proteção de Emergência. As regras da AAA estão
            disponíveis em www.adr.org ou pelo telefone 1-800-778-7879. Para
            arbitragem perante a JAMS, aplicar-se-ão as Regras e Procedimentos
            de Arbitragem Abrangentes da JAMS e os Protocolos de Descoberta de
            Arbitragem Recomendados pela JAMS para Casos Domésticos e
            Comerciais. As regras JAMS estão disponíveis em www.jamsadr.com ou
            ligando para 1-800-352-5267. Esta disposição rege no caso de
            conflito com as regras de arbitragem aplicáveis. Sob nenhuma
            circunstância serão aplicadas à arbitragem ações coletivas ou
            procedimentos ou regras representativas.
          </Text3>
          <Text3 as="p">
            Como este Website e estes Termos dizem respeito ao comércio
            interestadual, a Lei Federal de Arbitragem (“
            <strong>FAA</strong>”) rege a arbitrabilidade de todas as disputas.
            Entretanto, o árbitro aplicará a lei substantiva aplicável
            consistente com a FAA e o estatuto de limitações ou condição
            precedente aplicável para processar.
          </Text3>
          <Text3 as="p">
            <em>Sentença arbitral</em> - O árbitro poderá conceder,
            individualmente, qualquer medida que estaria disponível de acordo
            com a lei aplicável, e não terá o poder de conceder medidas contra
            ou em benefício de qualquer pessoa que não seja parte no processo. O
            árbitro fará qualquer concessão por escrito, mas não precisará
            apresentar uma declaração de motivos, a menos que solicitado por uma
            das partes ou se exigido pela lei aplicável. Tal sentença será final
            e obrigatória para as partes, exceto por qualquer direito de
            apelação previsto pela FAA ou outra lei aplicável, e poderá ser
            inscrita em qualquer tribunal com jurisdição sobre as partes para
            fins de execução.
          </Text3>
          <Text3 as="p">
            <em>Localização da Arbitragem</em> - Você ou nós podemos iniciar a
            arbitragem na Flórida ou no distrito judicial federal que inclui seu
            endereço de faturamento.
          </Text3>
          <Text3 as="p">
            <em>Pagamento de taxas e custos de arbitragem</em> - Desde que você
            faça um pedido por escrito antes do início da arbitragem, pagaremos
            todas as taxas de apresentação de arbitragem e taxas de audiência da
            AAA ou JAMS e quaisquer taxas, custos e despesas de audiência do
            árbitro, mediante seu pedido por escrito ao árbitro apresentado na
            primeira audiência probatória da arbitragem ou antes dela. Mas, você
            ainda será responsável por todos os honorários e custos adicionais
            que incorrer na arbitragem, que incluem, mas não estão limitados aos
            honorários advocatícios ou testemunhas especializadas. Além de
            quaisquer honorários e custos recuperáveis sob a lei aplicável, se
            você fornecer aviso e negociar de boa fé conosco, como previsto na
            seção acima intitulada "Resolução de Reclamação Pré-Arbitragem" e o
            árbitro concluir que você é a parte prevalecente na arbitragem, você
            terá o direito de recuperar honorários e custos razoáveis do
            advogado, conforme determinado pelo árbitro.
          </Text3>
          <Text3 as="p">Renúncia de Ação Coletiva</Text3>
          <Text3 as="p">
            Salvo disposição em contrário nesta disposição de arbitragem, o
            árbitro não pode consolidar mais de uma reivindicação de uma pessoa
            e não pode de outra forma presidir qualquer forma de processo ou
            reivindicação de classe ou representativa (tal como uma ação
            coletiva, ação consolidada, ação representativa ou ação particular
            do procurador geral), a menos que tanto você como nós concordemos
            especificamente em fazê-lo por escrito após o início da arbitragem.
            Se o cliente optar por prosseguir com sua disputa em juízo optando
            por não participar desta disposição de arbitragem, conforme
            especificado acima, esta Dispensa de Ação Coletiva não se aplicará a
            você. Nem você, nem qualquer outro usuário deste website pode ser um
            representante de classe, membro de classe, ou de outra forma
            participar de um processo de classe, consolidado ou representativo
            sem ter cumprido os requisitos de não participação acima.
          </Text3>
          <Text3 as="p">Renúncia do Júri</Text3>
          <Text3 as="p">
            Você entende e concorda que ao aceitar esta disposição de arbitragem
            nestes Termos, você e cada um de nós estamos renunciando ao direito
            a um julgamento pelo júri ou a um julgamento perante um juiz em um
            tribunal público. Na ausência desta disposição de arbitragem, você e
            nós poderíamos ter tido o direito ou a oportunidade de apresentar
            disputas em um tribunal, perante um juiz ou júri, e/ou de participar
            ou ser representados em um caso apresentado em tribunal por outros
            (incluindo ações coletivas). Salvo disposição em contrário abaixo,
            esses direitos são renunciados. Outros direitos que você teria se
            fosse a um tribunal (por exemplo, os direitos de apelação e certos
            tipos de descoberta) podem ser mais limitados ou também podem ser
            renunciados.
          </Text3>
          <Text3 as="p">Severidade</Text3>
          <Text3 as="p">
            Se qualquer cláusula dentro desta disposição arbitral (exceto a
            cláusula de Isenção de Ação Coletiva acima) for considerada ilegal
            ou inexeqüível, essa cláusula será separada desta disposição
            arbitral, cujo restante terá força e efeito total. Se a cláusula de
            Isenção de Ação Coletiva for considerada ilegal ou inexeqüível, toda
            esta disposição de arbitragem será inexeqüível e a disputa será
            decidida por um tribunal.
          </Text3>
          <Text3 as="p">Continuação</Text3>
          <Text3 as="p">
            Esta disposição de arbitragem sobreviverá à conclusão de seu
            Ironhack Bootcamp conosco ou com nossas afiliadas e ao seu uso
            descontinuado deste site. Não obstante qualquer disposição em
            contrário nestes Termos, concordamos que se fizermos qualquer
            mudança nesta disposição de arbitragem (que não seja uma mudança no
            endereço da notificação), você poderá rejeitar tal mudança e exigir
            que podemos aderir ao presente idioma nesta disposição de arbitragem
            se surgir uma disputa entre nós.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">LÍNGUA</Text2>
          <Text3 as="p">
            As partes exigiram expressamente que este acordo e todos os
            documentos e avisos relacionados a ele sejam redigidos no idioma
            inglês.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">GERAL</Text2>
          <Text3 as="p">
            Pensamos que a comunicação direta resolve a maioria das questões -
            se acharmos que você não está cumprindo estes Termos, nós lhe
            diremos. Nós lhe forneceremos até mesmo as ações corretivas
            necessárias recomendadas, pois valorizamos este relacionamento.
          </Text3>
          <Text3 as="p">
            Entretanto, certas violações destes Termos, conforme determinado por
            nós, podem exigir o cancelamento imediato de seu acesso a este
            Website sem aviso prévio a você. A Lei Federal de Arbitragem, lei
            estadual da Flórida e lei federal aplicável nos EUA, sem considerar
            a escolha ou conflitos de disposições legais, regerá estes Termos.
            As leis estrangeiras não se aplicam. Exceto para disputas sujeitas a
            arbitragem conforme descrito acima, quaisquer disputas relacionadas
            a estes Termos ou a este Website serão ouvidas nos tribunais
            localizados no condado de Miami-Dade, Flórida. Se qualquer um destes
            Termos for considerado inconsistente com a lei aplicável, então, tal
            será interpretado para refletir as intenções das partes, e nenhum
            outro termo será modificado. Ao optar por não aplicar qualquer um
            destes Termos, não estamos renunciando a nossos direitos. Estes
            Termos são o acordo completo entre você e nós e, portanto,
            substituem todas as negociações, discussões ou acordos anteriores ou
            contemporâneos entre todos sobre este Website. Os direitos de
            propriedade, renúncia de garantias, representações feitas por você,
            indenizações, limitações de responsabilidade e disposições gerais
            sobreviverão a qualquer rescisão destes Termos.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">AVISO AO CONSUMIDOR DA CALIFÓRNIA</Text2>
          <Text3 as="p">
            De acordo com a Seção 1789.3 do Código Civil da Califórnia, os
            usuários da Califórnia têm direito ao seguinte aviso de direitos do
            consumidor: Este Website e Serviço são fornecidos por Ironhack, 990
            Biscayne Blvd, Ste. 502, Miami FL 33132. Se Você tiver alguma
            pergunta ou reclamação sobre o Website ou Serviço, por favor, entre
            em contato conosco diretamente em{' '}
            <a href="mailto: legal@ironhack.com">legal@ironhack.com</a>. Você
            também pode entrar em contato conosco escrevendo para Ironhack, 990
            Biscayne Blvd, Ste. 502, Miami FL 33132. Os residentes da Califórnia
            podem contatar a Unidade de Assistência a Reclamações da Divisão de
            Serviços ao Consumidor do Departamento de Assuntos do Consumidor da
            Califórnia pelo correio para 1625 North Market Blvd., Sacramento, CA
            95834 ou pelo telefone (916) 445-1254 ou (800) 952-5210 ou
            Deficiência Auditiva para TDD (800) 326-2297 ou TDD (916) 322-1700.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">FALE CONOSCO</Text2>
          <Text3 as="p">
            Se você tiver alguma dúvida sobre estes Termos ou se precisar entrar
            em contato conosco por qualquer motivo, você pode nos contatar em{' '}
            <a href="mailto: legal@ironhack.com">legal@ironhack.com</a>.
          </Text3>
        </Question>
      </Layout>
      <Footer page={pageName} />
    </>
  );
};

export const query = graphql`
  query {
    locales: allLocale(
      filter: {
        lng: { eq: "br" }
        ns: { regex: "/(seo)|(breadcrumbs)|(menu)|(banner)/" }
      }
    ) {
      ...TranslationFragment
    }
  }
`;

export default withI18next('br')(TermOfUse);
